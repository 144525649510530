import React, { SetStateAction } from 'react';
import { BasicModal } from './BasicModal';
import { Button, Typography } from '@mui/material';
import { HabitData, HabitDataDictionary } from '../data/types';
import { IoTrash } from "react-icons/io5";
import { useRecoilState, useRecoilValue } from 'recoil';
import { habitDataAtom, userDataAtom } from '../recoil/recoilStates';
import { deleteServerHabit } from '../network/serverData';

interface HabitEditModalProps {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    habit: HabitData;
}
export const HabitEditModal = ({ open, setOpen, habit }: HabitEditModalProps) => {

    const [habitData, setHabitData] = useRecoilState(habitDataAtom);
    const userData = useRecoilValue(userDataAtom);

    const handleClose = () => setOpen(false);

    const deleteHabit = async () => {
        if (!userData) return;
        const serverResult = deleteServerHabit(habit);
        
        if (!serverResult) {
            alert('Something went wrong with deleting from the server. Please try again later.')
        } else {
            const newHabitData: HabitDataDictionary = { ...habitData };
            delete newHabitData[habit.id];
            
            setHabitData(newHabitData);
        }
        

    }

    return (
        <BasicModal
            open={open}
            onClose={handleClose}
            backdropSx={{ backgroundColor: 'rgba(0, 0, 0, 0.07)' }}
            sx={{ boxShadow: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
            <Typography variant='h5'>
                {habit.label}
            </Typography>
            <Button variant='contained' color='error' onClick={deleteHabit}>
                <IoTrash style={{ paddingRight: '5px' }} />
                Delete
            </Button>
            <Button variant='contained'>
                Submit
            </Button>
        </BasicModal>
    )
}