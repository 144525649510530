import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { BasicModal } from './BasicModal';
import { LocalizationProvider, TimeClock, TimeView } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { userDataAtom } from '../recoil/recoilStates';
import { HabitData } from '../data/types';
import { useModifyHabitData } from '../useHook/useModifyHabitData';


const buttonStyle = {
    margin: '0px 10px'
}


const ClockWrapper = styled.div`

    display: flex;
    position: relative;
    max-width: 300px;
    margin: 0 auto;

    .MuiClockNumber-root:nth-of-type(12) {
        visibility: hidden;
        background-color: red;
    }

    .MuiClockNumber-root:nth-of-type(12):after{
        visibility: visible;
        display: block;
        content: '0';
        position: absolute;
    }


    .MuiTimeClock-arrowSwitcher{
        //position: relative;
        top: 0;
        right: 0;
    }
/*     
    .MuiClock-circle {
    background-color: green;
    } */
`

interface HabitTrackModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    habit: HabitData;
}
export const HabitTrackModal = ({ open, setOpen, habit }: HabitTrackModalProps) => {

    const userData = useRecoilValue(userDataAtom);

    const [duration, setDuration] = React.useState<number>(0);
    const { gainExp } = useModifyHabitData();

    const [hours, setHours] = React.useState<number>(0);
    const [minutes, setMinutes] = React.useState<number>(0);
    // const [isHour]


    const handleClose = () => setOpen(false);

    const handleDurationChange = (newDuration: any, selectionState: any) => {
        if (newDuration) {
            //console.log(newDuration);
            
            const hours = newDuration['$H'];
            const minutes = newDuration['$m'];

            setHours(hours);
            setMinutes(minutes);

            setDuration((hours * 60) + minutes);
            
            if (selectionState !== 'finish') {
                return;
            }

            console.log(`The time is ${newDuration['$H']} and ${newDuration['$m']} minutes `);
        }
    }


    const handleSubmit = async() => {
        if (!userData) return;

        await gainExp(habit, duration);
        
        setHours(0);
        setMinutes(0);
        setDuration(0);

        // const since the habit state is not changing fast enouigh
        
        handleClose();
    }

    return (
        <BasicModal open={open} onClose={handleClose}>
            <Typography paddingBottom={'30px'}>
                You're leveling up in '{habit.label}'!
            </Typography>
            <ClockWrapper>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimeClock
                        //onViewChange={}
                        onChange={handleDurationChange}
                        views={['hours', 'minutes']}
                        minutesStep={5}
                        sx={{
                            paddingTop: 2.5,
                        }}
                        showViewSwitcher={true}
                    />
                </LocalizationProvider>
            </ClockWrapper>

            <Typography textAlign={'center'} variant='h5'>
                {hours} hrs {minutes} mins
            </Typography>

            <Typography textAlign={'center'} variant='h6'>
                {duration} xp
            </Typography>

            <Box display={'flex'} marginTop={2} width={'100%'} justifyContent={'center'} >
                <Button variant='contained' onClick={handleClose} sx={buttonStyle} color='error'>Cancel</Button>
                <Button variant='contained' onClick={handleSubmit} sx={buttonStyle}>Submit</Button>
            </Box>

        </BasicModal>
    )
}