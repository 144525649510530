import { HabitData, HabitDataDictionary } from "../data/types";


// This uses the old way of labels to sort.. Might want to get rid of / change?
export const sortHabits = (habitsDataDict:HabitDataDictionary): HabitDataDictionary => {
    const habitsData = Object.values(habitsDataDict);

    const roots = habitsData.filter(item => !item.parent);

    const findChildren = (parent: HabitData) => {
        let children = habitsData.filter(item => item.parent === parent.id);
        let sortedChildren: HabitData[] = [];

        for (let child of children) {
            sortedChildren.push(child, ...findChildren(child));
        }

        return sortedChildren;
    }

    let sortedData: HabitData[] = [];
    for (let root of roots) {
        sortedData.push(root, ...findChildren(root));
    }

    const sortedDict: HabitDataDictionary = {};
    for (let item of sortedData) {
        sortedDict[item.id] = item;
    }

    return sortedDict;
} 