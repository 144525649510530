
export const basicModalBoxStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    bgcolor: 'background.paper',
    border: '2px solid #000', 
    borderRadius: '8px', 
    boxShadow: 24, 
    padding: 4
}