import { convertToDictionaryWithDepth } from "../helpers/convertToDictionaryWithDepth";
import { HabitData } from "./types"
import { v4 as uuidv4 } from 'uuid';

// export const habitTestData: { [key: string]: HabitData } = {
//     'Coding': {
//         id: uuidv4(),
//         label: 'Coding',
//         value: 180,
//         depth: 1,
//         parent: 'Computing'
//     },
//     'Reddit': {
//         id: uuidv4(),
//         label: 'Reddit',
//         value: 423,
//         depth: 0
//     },
//     'You know :D': {
//         id: uuidv4(),
//         label: 'You know :D',
//         value: 30,
//         depth: 0
//     },
//     'JavaScript': {
//         id: uuidv4(),
//         label: 'JavaScript',
//         value: 100,
//         depth: 2,
//         parent: 'Coding'
//     },
//     'TypeScript': {
//         id: uuidv4(),
//         label: 'TypeScript',
//         value: 80,
//         depth: 2,
//         parent: 'Coding'
//     },
//     'Cleaning': {
//         id: uuidv4(),
//         label: 'Cleaning',
//         value: 0,
//         depth: 0
//     },
//     'Computing': {
//         id: uuidv4(),
//         label: 'Computing',
//         value: 1200,
//         depth: 0
//     },
//     'Cleaning2': {
//         id: uuidv4(),
//         label: 'Cleaning2',
//         value: 0,
//         depth: 0
//     },
//     'Cleaning3': {
//         id: uuidv4(),
//         label: 'Cleaning3',
//         value: 0,
//         depth: 0
//     },
// }


export interface UserHabitsExample {
    //id: string;
    label: string;
    value: number;
    parent?: string;
    //depth: number; // The depth should not go deeper than 2
}

export const userHabitsExample: UserHabitsExample[] = [
    {
        label: 'Coding',
        value: 180,
        parent: 'Computing'
    },
    {
        label: 'Reddit',
        value: 423,
    },
    {
        label: 'You know :D',
        value: 30,
    },
    {
        label: 'JavaScript',
        value: 100,
        parent: 'Coding'
    },
    {
        label: 'TypeScript',
        value: 80,
        parent: 'Coding'
    },
    {
        label: 'Cleaning',
        value: 0,
    },
    {
        label: 'Computing',
        value: 1200,
    }
]


interface ServerHabitData extends UserHabitsExample  {
    id: string;
}

export const createHabitData = (habits: UserHabitsExample[]) => {
    //const dictionary: { [id: string]: ServerHabitData } = {};

    // for (let habit of habits) {
    //     const uuidv4Val = uuidv4();
    //     dictionary[uuidv4Val] = {...habit, id: uuidv4Val};
    // }


    const newHabitArray = habits.map((habit, index) => {
        const uuidv4Val = uuidv4();
        return {...habit, id: uuidv4Val, depth: 0}
    })

    return convertToDictionaryWithDepth(newHabitArray)
}

//export const habitTestData = createHabitData(userHabitsExample);
//console.log(`The habitTestData is: `, habitTestData)
// const habitTestData = convertToDictionaryWithDepth(userHabitsExample);

export const userTestData = {
    displayName: 'Jobodo',
    email: 'jobodo@example.com',
    password: 'Choco1ator'
}