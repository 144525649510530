import React from 'react';

export const useLongPress = (callback = () => {}, ms = 1000) => {
    const [startLongPress, setStartLongPress] = React.useState(false);

    const timerIdRef = React.useRef<number | null>(null);

    React.useEffect(() => {
        if (startLongPress) {
            timerIdRef.current = window.setTimeout(callback, ms);
        } else {
            if (timerIdRef.current) window.clearTimeout(timerIdRef.current);
        }
        return () => {
            if (timerIdRef.current) window.clearTimeout(timerIdRef.current);
        }
    }, [callback, ms, startLongPress])


    return {
        onMouseDown: () => setStartLongPress(true),
        onMouseUp: () => setStartLongPress(false),
        onMouseLeave: () => setStartLongPress(false),
        onTouchStart: () => setStartLongPress(true),
        onTouchEnd: () => setStartLongPress(false)
    }
}