import React, { SetStateAction } from 'react';
import { ProgressBar } from './ProgressBar';
import { Box, Button, Card, IconButton, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { IoIosAdd } from 'react-icons/io';
import { HabitTrackModal } from './HabitTrackModal';
import { HabitData } from '../data/types';
import { useLongPress } from '../useHook/useLongPress';
import { HabitEditModal } from './HabitEditModal';


const defaultCardStyle = {
    margin: '8px',
    padding: '10px 0px 0px 0px'
}

interface HabitBarProps {
    sx?: SxProps<Theme>;
    habit: HabitData;
    isIndented?: boolean;

    //code this a bit better?
}
export const HabitCard = ({ habit, sx, isIndented = true }: HabitBarProps) => {
    const theme = useTheme()

    
    const [open, setOpen] = React.useState<boolean>(false);
    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    
    
    const openHabitTrackModal = () => {
        //alert("Level Up!")
        setOpen(true);
    }
    
    const marginLeft = `${isIndented ? habit.depth * 12 + 8 : 8}px`;
    
    const setEditCb = React.useCallback(() => setIsEdit(true), [setIsEdit])
    const longPressCallbacks = useLongPress(setEditCb)


    return (
        <Card
            sx={{
                display: 'flex', flexDirection: 'column', border: `1px solid ${theme.palette.primary.light}`,
                ...defaultCardStyle, marginLeft, ...sx,
            }} 
            {...longPressCallbacks}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ margin: 2, flex: 1, textAlign: 'start' }}>
                    <Typography>{habit.label}</Typography>
                    <Typography fontSize={'0.85em'} padding={'0px 0px'}>
                        {`(Level ${getLevelFromExperience(habit.value)})`}
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex', backgroundColor: theme.palette.primary.light,
                    height: 45, width: 45, borderRadius: '50%', marginRight: 2,
                    alignItems: 'center', justifyContent: 'center'
                }}>
                    <Box>
                        <Button>
                            <IoIosAdd onClick={openHabitTrackModal} size={'90%'} color={theme.palette.text.primary}/>
                        </Button>
                    </Box>
                </Box>
            </Box>
            <ProgressBar value={habit.value} height={'15px'} />
            <HabitTrackModal habit={habit} open={open} setOpen={setOpen} />
            <HabitEditModal open={isEdit} setOpen={setIsEdit} habit={habit}/>
        </Card>
    )
}

function getLevelFromExperience(exp: number) {
    return Math.floor(exp / 100) + 1;
}