import React from 'react';
import { Box, Button, TextField, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { userDataAtom } from "../recoil/recoilStates"
import { serverLogin } from '../network/serverData';


export const Login = () => {

    const setUserData = useSetRecoilState(userDataAtom);

    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');

    const [emailError, setEmailError] = React.useState<boolean>(false);
    const [passwordError, setPasswordError] = React.useState<boolean>(false);


    const onSubmit = async (email: string, password: string) => {

        if (email === '' || password === '') {
            if (email === '') {
                setEmailError(true);
            } else {
                setEmailError(false);
            }
            if (password === '') {
                setPasswordError(true);
            } else {
                setPasswordError(false)
            }
            return
        }

        document.cookie = '';
        const authUser = await serverLogin(email, password);

        if (!authUser) {
            alert(`Could not log you in. 
            \nPlease check email and password are correct and try again.`)
        } else {
            setUserData(authUser);
        }
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', padding: '8vh'}}>
            <Typography>Login to Growth</Typography>

            <Box
                component='form'
                autoComplete='off'
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <TextField
                    required
                    id='login-email'
                    label='Email'
                    placeholder='E.g. name@example.com'
                    value={email}
                    type='email'
                    onChange={(e) => setEmail(e.target.value)}
                    helperText={emailError ? "Please enter your email." : undefined}
                />
                <TextField
                    required
                    id='login-password'
                    label='Password'
                    value={password}
                    type='password'
                    onChange={(e) => setPassword(e.target.value)}
                    helperText={passwordError ? "Please enter your password." : undefined}
                />


                <Button variant='contained' sx={{ margin: 1 }} onClick={() => onSubmit(email, password)}>
                    Submit
                </Button>
            </Box>

            <Typography>No account? <Link to="/register">Register here</Link></Typography>

        </Box>
    )
}