import { Button } from '@mui/material';
import React from 'react';
import { HabitAddModal } from './HabitAddModal';

export const HabitAddButton = () => {

    const [open, setOpen] = React.useState<boolean>(false);

    const pageBottomRef = React.useRef<HTMLDivElement>(null);


    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                variant='contained'
                sx={{
                    margin: 1,
                    height: '58px',
                    width: '90%'
                }}
            >
                Add new habit
            </Button>
            <div // Div to scroll to bottom of the page
                ref={pageBottomRef}
                style={{ paddingBottom: 10 }}
            />
            <HabitAddModal open={open} setOpen={setOpen} pageBottomRef={pageBottomRef}/>
        </>
    )

}