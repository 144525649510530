import { Backdrop, Box, Fade, Modal, SxProps, Theme } from '@mui/material';
import React from 'react';
import { basicModalBoxStyle } from '../data/defaults';

interface BasicModalProps {
    open: boolean;
    onClose: () => void; // re-check this??
    children: React.ReactNode;
    sx?: SxProps<Theme>;
    backdropSx?: SxProps<Theme>;
}
export const BasicModal = ({open, onClose, children, backdropSx, sx}: BasicModalProps) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                    sx: backdropSx
                }
            }}
            disableScrollLock
        >
            <Fade in={open}>
                <Box sx={{...basicModalBoxStyle, ...sx}}>
                    {children}
                </Box>
            </Fade>
        </Modal>
    )
}