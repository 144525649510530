import React from 'react';
import { HabitData } from '../data/types';
import { useRecoilState, useRecoilValue } from 'recoil';
import { habitDataAtom, userDataAtom } from '../recoil/recoilStates';
import { updateServerHabitDuration } from '../network/serverData';

export const useModifyHabitData = () => {

    const [habitData, setHabitData] = useRecoilState(habitDataAtom);
    const userData = useRecoilValue(userDataAtom);

    const gainExpClientSide = (habit: HabitData, duration: number) => {
        if (habit.parent) {
            gainExpClientSide(habitData[habit.parent], duration)
        }

        setHabitData((existingHabitData) => {
            return {
                ...existingHabitData,
                [habit.id]: {
                    ...existingHabitData[habit.id],
                    value: existingHabitData[habit.id].value + duration
                }
            }
        });
    }

    const gainExp = async(habit: HabitData, duration: number) => {
        if (!userData) {
            console.error(`Something went wrong with trying to gainExp as userData is undefined`);
            return;
        }
        let serverResponse = await updateServerHabitDuration(habit.id, duration);

        console.log(`The available time is: `, serverResponse)

        if (!serverResponse) {
            alert(`Something went wrong with server, please try again later.`)
        } else {
            gainExpClientSide(habit, duration);
        }
    }

    
    return { gainExp }
}