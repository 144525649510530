import React from 'react';

import { Box, Button, TextField, Typography } from '@mui/material';
import { registerAndCreateServerUser, serverLogin } from '../network/serverData';
import { Link, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { userDataAtom } from '../recoil/recoilStates';

const passwordValidCheck = (pass: string) => {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    //const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;

    const upperCaseCheck = uppercaseRegExp.test(pass);
    const lowerCaseCheck = lowercaseRegExp.test(pass);
    const digitCheck = digitsRegExp.test(pass);
    const minLengthCheck = minLengthRegExp.test(pass);

    if (!upperCaseCheck || !lowerCaseCheck || !digitCheck || !minLengthCheck) {
        return false;
    }
    return true;
}

export const Register = () => {

    const [displayName, setDisplayName] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');


    const [displayNameError, setDisplayNameError] = React.useState<boolean>(false);
    const [emailError, setEmailError] = React.useState<boolean>(false);
    const [passwordError, setPasswordError] = React.useState<boolean>(false);

    const setUserData = useSetRecoilState(userDataAtom);

    const navigate = useNavigate();



    const onSubmit = async (displayName: string, email: string, password: string) => {
        // Set errors for forms
        if (displayName === '' || email === '' || password === '') {
            if (displayName === '') {
                setDisplayNameError(true);
            } else {
                setDisplayNameError(false)
            }
            if (email === '' || !(/\S+@\S+\.\S+/.test(email))) {
                setEmailError(true);
            } else {
                setEmailError(false);
            }
            if (password === '' || !passwordValidCheck(password)) {
                setPasswordError(true);
            } else {
                setPasswordError(false)
            }
            return
        }

        // do stuff here
        const result = await registerAndCreateServerUser(displayName, email, password);
        if (!result) {
            alert(`Something went wrong. Please try again.`)
        }

        const authUser = await serverLogin(email, password);
        if (!authUser) {
            navigate('/login')
        } else {
            setUserData(authUser)
            navigate('/');
        }
    }

    return (
        <>
            <Box sx={{
                flexGrow: 1,
                paddingTop: '7vh'
                //...appContentStyle
            }}>
                {/* <AppBar position='fixed'>
                    <Typography variant='h4'>Register</Typography>
                </AppBar> */}
                <Typography variant='h5'>Register</Typography>
                <Typography color='GrayText'>
                    Enter your details below to start your Growth journey!
                </Typography>
                <Box
                    component='form'
                    autoComplete='off'
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <TextField
                        required
                        error={displayNameError}
                        id='register-display-name'
                        label='Display Name'
                        placeholder='E.g. John Brown'
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        helperText={displayNameError ? "Please enter a display name." : undefined}
                    />
                    <TextField
                        required
                        error={emailError}
                        id='register-email'
                        label='Email'
                        placeholder='E.g. name@example.com'
                        value={email}
                        type='email'
                        onChange={(e) => setEmail(e.target.value)}
                        helperText={emailError ? "Please enter a valid email." : undefined}
                    />
                    <TextField
                        required
                        error={passwordError}
                        id='login-password'
                        label='Password'
                        value={password}
                        type='password'
                        onChange={(e) => setPassword(e.target.value)}
                        helperText={passwordError ? "Please enter a valid password." : undefined}
                    />
                    <Typography
                        color='GrayText'
                        sx={{ margin: '0px 10vw' }}
                        variant='subtitle2'
                        fontSize='1.1vh'
                    >
                        Password must be a minimum of 8 characters long, contain a uppercase, lowercase and digit character.
                    </Typography>

                    <Button
                        variant='contained' sx={{ margin: 1 }}
                        onClick={() => onSubmit(displayName, email, password)}>
                        Submit
                    </Button>

                    <Typography>Already have an account? <Link to="/login">Login here</Link></Typography>
                </Box>
            </Box>
        </>

    )
}