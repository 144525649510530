import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from '@emotion/react';
import { darkCoralPinkTheme, darkMatchaGreenTheme, lightCoralPinkTheme, lightMatchaGreenTheme } from './data/theme';

import { sortHabits } from './helpers/sortHabits';
import { useRecoilState } from 'recoil';
import { habitDataAtom, userDataAtom } from './recoil/recoilStates';
import { fetchServerUserData, fetchServerUserHabits } from './network/serverData';
import { convertToDictionaryWithDepth } from './helpers/convertToDictionaryWithDepth';
import { Register } from './pages/Register';
import { Home } from './pages/Home';
import { NoPage } from './pages/NoPage';
import { Login } from './pages/Login';

export const appContentStyle = {
    paddingTop: '70px'
}

function App() {

    const [userData, setUserData] = useRecoilState(userDataAtom);
    const [habitData, setHabitData] = useRecoilState(habitDataAtom);

    // const [isLoginModal, setIsLoginModal] = React.useState<boolean>(() => {
    //     if (!userData) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // });


    React.useEffect(() => {
        if (document.cookie) {
            const fetchUserData = async () => {
                const fetchedUserData = await fetchServerUserData();
                setUserData(fetchedUserData);
                //console.log(`The userdata got from the server is: `, fetchedUserData);
            }
            fetchUserData();
        }
    }, [setUserData])


    React.useEffect(() => {
        if (!userData?.id) return;
        const fetchHabitData = async () => {
            let fetchedHabitData = await fetchServerUserHabits();

            if (fetchedHabitData) {
                fetchedHabitData = convertToDictionaryWithDepth(fetchedHabitData);
                fetchedHabitData = sortHabits(fetchedHabitData);
            }
            setHabitData(fetchedHabitData);
        }
        fetchHabitData();
    }, [userData?.id, setHabitData])


    React.useEffect(() => {
        console.log(`User data was changed.`)
    }, [userData])

    React.useEffect(() => {
        console.log(`Habit data was changed`, habitData);
        sortHabits(habitData)
    }, [habitData])



    console.log(`The habitData dictionary is:`, habitData)

    console.log(`The react api url is: `, process.env.REACT_APP_API_URL)
    console.log(`Is production mode?`, process.env.NODE_ENV)


    const hasToken = document.cookie.includes('growth_token=');


    return (
        <ThemeProvider theme={lightMatchaGreenTheme}>
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        {hasToken ?
                            <>
                                <Route path='/' index element={<Home />} />
                                <Route path='*' element={<NoPage />} />
                            </>
                            :
                            <>
                                <Route path='register' element={<Register />} />
                                <Route path='login' element={<Login />} />
                                <Route path='*' element={<Login />} />
                            </>
                        }
                    </Routes>
                </BrowserRouter>


                {/* {userData ?
                    null
                    :
                    <LoginModal open={isLoginModal} setOpen={setIsLoginModal} />
                } */}


            </div>
        </ThemeProvider>
    );
}

export default App;
