import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { habitDataAtom } from '../recoil/recoilStates';
import { HabitData } from '../data/types';

interface SelectParentProps {
    setDepthValue: React.Dispatch<React.SetStateAction<number>>;
    setParentValue: React.Dispatch<React.SetStateAction<string>>;
}
export const SelectParent = ({setDepthValue, setParentValue}: SelectParentProps) => {

    const habitData =  useRecoilValue(habitDataAtom);

    const availableParents = React.useMemo(() => {
        return Object.keys(habitData).map((habit) => {
            if (habitData[habit].depth >= 2)
                return undefined;
            return habitData[habit]
            //return habitData[habit].label;
        }).filter(it => it);
    }, [habitData])

    const handleChange = (event:any, newValue: HabitData | null | undefined) => {
        console.log(`The selectParent newValue is`, newValue)
        console.log(`The parent depth is: `, habitData[newValue!.id]);
        if (!newValue || newValue === null) 
            return;
        setParentValue(newValue.id);
        setDepthValue(habitData[newValue.id].depth + 1);
    }

    return (
        <Autocomplete
            options={availableParents ? availableParents : []}
            getOptionLabel={(option) => option ? option.label : ''}
            onChange={(event, newValue) => handleChange(event, newValue)}
            renderInput={(params) => <TextField {...params} label="Parent"/>}
        />
    )
}