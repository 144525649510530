import React from 'react';
import Box from '@mui/material/Box'
import { LinearProgress, LinearProgressProps, Typography, useTheme } from '@mui/material';

interface ProgressBarProps extends LinearProgressProps {
    height?: string;
    value: number;
}
export const ProgressBar = ({ value, height, ...linearProgressProps }: ProgressBarProps) => {
    const theme = useTheme();
    const [showProgress, setShowProgress] = React.useState<boolean>();

    const showProgressOnClick = () => {
        setShowProgress(!showProgress);
    }


    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box position='relative' sx={{ width: '100%'}} onClick={showProgressOnClick}>
                <LinearProgress {...linearProgressProps} sx={{ height: height }} variant='determinate' value={value % 100} />
                {showProgress ?
                    <Box
                        position='absolute'
                        top='0'
                        left='0'
                        width='100%'
                        height='100%'
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                    >
                        <span style={{ color: theme.palette.text.secondary }}>{`${value % 100}/100`}</span>
                    </Box>
                    :
                    null}
            </Box>
            {/* <Box sx={{ minWidth: 35 }}>
                <Typography>{`${value % 100}%`}</Typography>
                <Typography>{`${value % 100}/100`}</Typography>
            </Box> */}
        </Box>
    )
}