import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export const NoPage = () => {
    return (
        <>
            <Typography>No page exists for this route, please return. </Typography>
            {document.cookie.includes('growth_token=') ?
                <Link to="/">Return</Link>
                :
                <Link to="/register">Return</Link>
            }
        </>
    )
}