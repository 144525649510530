import { Box, Button, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { userDataAtom } from '../recoil/recoilStates';
import { IoIosTime } from 'react-icons/io';

export const TimeBar = () => {
    const [progress, setProgress] = React.useState(0);
    const userData = useRecoilValue(userDataAtom);

    React.useEffect(() => {
        if (!userData?.availableTime) return;
        setProgress((userData?.availableTime / 1440) * 100)
    }, [userData?.availableTime])


    const timeAvailable = React.useMemo(() => {
        if (!userData) return;
        let hours = Math.floor(userData.availableTime / 60);
        let minutes = userData.availableTime % 60;
        return <Box>
            <Typography>{`${hours}h ${minutes.toString().padStart(2, '0')}m`}</Typography>
            <TimeBar />
        </Box>
    }, [userData])

    return (
        // <Box>
        //     <Button>
        //         <IoIosTime />
        //         {timeAvailable ? timeAvailable : 'TimeBar'}
                <Box sx={{ width: '100%' }}>
                    
                    <LinearProgress variant='determinate' value={progress} color='inherit' />
                </Box>
        //     </Button>
        // </Box>
    )
}