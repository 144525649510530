import { Autocomplete, Box, Button, IconButton, TextField, Typography } from '@mui/material';
import React from 'react';
import { BasicModal } from './BasicModal';
import { v4 as uuidv4 } from 'uuid';

import { IoIosHappy, IoIosOutlet, IoIosSad, IoIosThumbsDown, IoIosThumbsUp } from "react-icons/io";
import { SelectParent } from './SelectParent';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { habitDataAtom, userDataAtom } from '../recoil/recoilStates';
import { createServerHabit } from '../network/serverData';
import { sortHabits } from '../helpers/sortHabits';

const habitIconSize = '45px';

interface HabitAddModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    pageBottomRef: React.RefObject<HTMLDivElement>;
}
export const HabitAddModal = ({ open, setOpen, pageBottomRef }: HabitAddModalProps) => {

    const [goodSelected, setGoodSelected] = React.useState<boolean>(false);
    const [badSelected, setBadSelected] = React.useState<boolean>(false);

    const [formLabelValue, setFormLabelValue] = React.useState<string>('');
    const [formDepthValue, setFormDepthValue] = React.useState<number>(0);
    const [formParentValue, setFormParentValue] = React.useState<string>('');

    const userData = useRecoilValue(userDataAtom);
    const setHabitData = useSetRecoilState(habitDataAtom);

    const handleClose = () => setOpen(false);

    const goodClick = () => {
        if (badSelected)
            setBadSelected(false);

        setGoodSelected(!goodSelected);
    }

    const badClick = () => {
        if (goodSelected)
            setGoodSelected(false);

        setBadSelected(!badSelected);
    }

    //TODO Upon adding a habit the list should sort
    //This will not work correctly at the moment
    const addHabit = async (newHabit: string, habitDepth: number, habitParent: string) => {
        if (!userData) {
            console.error(`Something went wrong adding a habit because there is no userData`);
            return;
        }
        const habitUuidv4Val = uuidv4()
        const habit = {
            label: newHabit,
            id: habitUuidv4Val,
            value: 0,
            depth: habitDepth,
            parent: habitParent ? habitParent : undefined
        }

        const serverResult = await createServerHabit(habit);
        console.log(`The server result is also:`, serverResult);
        if (!serverResult) {
            alert('Something went wrong with the server. Please try again.')
        }
        else {
            setHabitData((existingHabitData) => {
                
                return sortHabits({
                    ...existingHabitData,
                    [habitUuidv4Val]: habit
                })
            })
            
            //sortHabits(habitData);
        }

        setFormLabelValue('');
        setFormDepthValue(0);
        setFormParentValue('');
        setBadSelected(false);
        setGoodSelected(false);

        
        console.log(`Should be scrolling to the bottom of the page`)
        pageBottomRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "end"
        })
        handleClose();
    }

    console.log(`Userdata is `, userData);

    React.useEffect(() => {
        console.log(`FormDepthValue is: `, formDepthValue);
        console.log(`FormParentValue is: `, formParentValue);
    }, [formDepthValue, formParentValue])


    return (
        <BasicModal open={open} onClose={handleClose}>
            <Typography variant='h6' component='h2' align='center'>
                Add a habit
            </Typography>
            <Box
                component='form'
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
                autoComplete='off'
                noValidate
            >
                <TextField
                    required
                    id='habit-label'
                    label='Habit'
                    placeholder='E.g. Cleaning'
                    value={formLabelValue}
                    onChange={(e) => setFormLabelValue(e.target.value)}
                />

                <SelectParent setDepthValue={setFormDepthValue} setParentValue={setFormParentValue} />

                <Typography sx={{ mt: 2 }}>
                    This habit is:
                </Typography>
                <Box sx={{ margin: 2 }}>
                    <IconButton onClick={goodClick}>
                        <IoIosThumbsUp size={habitIconSize} color={goodSelected ? '#9EBD6D' : 'grey'} />
                    </IconButton >
                    <IconButton onClick={badClick}>
                        <IoIosThumbsDown size={habitIconSize} color={badSelected ? 'salmon' : 'grey'} />
                    </IconButton>
                </Box>
                <Button variant='contained' sx={{ margin: 1 }} onClick={() => addHabit(formLabelValue, formDepthValue, formParentValue)}>Submit</Button>
            </Box>
        </BasicModal>
    )
}