import { Theme, createTheme } from '@mui/material/styles';

// Might want to make this smaller for say mobile?
const fontSize = 15;

export type availableAppThemes = "Light Coral Pink" | "Light Matcha Green" |
    "Dark Coral Pink" | "Dark Matcha Green";

const matchaBase = '#B2D8B2';

export const lightCoralPinkTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#FF7F7F',
        },
        background: {
            default: '#FEFDFB'
        },
        text: {
            primary: '#21130d'
        }
        // Add any other customisations I want here
    },
    typography: {
        fontSize: fontSize,
    }
})

export const lightMatchaGreenTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#B2D8B2'
        },
        text: {
            primary: '#21130d'
        },
        background: {
            default: '#FEFDFB'
        },
    },
    typography: {
        fontSize: fontSize
    }
})

export const darkCoralPinkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#FF7F7F'
        },
        background: {
            default: '#2B2A33'
        }
    },
    typography: {
        fontSize: fontSize
    }
})

export const darkMatchaGreenTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#B2D8B2',
        },
        background: {
            default: '#2B2A33'
        }
    },
    typography: {
        fontSize: fontSize,
    }
});


export interface AppThemeProps {
    name: string;
    theme: Theme;
}
export const appThemes: { [theme in availableAppThemes]: AppThemeProps } = {
    "Light Coral Pink": {
        name: "Light Coral Pink",
        theme: lightCoralPinkTheme
    },
    "Light Matcha Green": {
        name: "Light Matcha Green",
        theme: lightMatchaGreenTheme
    },
    "Dark Coral Pink": {
        name: "Dark Coral Pink",
        theme: darkCoralPinkTheme
    },
    "Dark Matcha Green": {
        name: "Dark Matcha Green",
        theme: darkMatchaGreenTheme
    },
};