import { AppBar, Box, Button, IconButton, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { IoIosMenu, IoIosTime } from 'react-icons/io';
import { createServerUserData, fetchServerAvailableTime } from '../network/serverData';
import { userTestData } from '../data/test';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userDataAtom } from '../recoil/recoilStates';
import { TimeBar } from './TimeBar';


export const Header = () => {

    const [userData, setUserData] = useRecoilState(userDataAtom);

    console.log(`The newest userData is: `, userData)

    // const [hours, setHours] = React.useState<Number>(0);
    // const [minutes, setMinutes] = React.useState<Number>(0);

    const whatevaTestButton = () => {
        // alert(`I am being clicked!`);
        const dataToSendServer = {
            displayName: userTestData.displayName, email: userTestData.email,
            password: userTestData.password
        }

        //console.log(`Data to add to server: `, dataToSendServer)

        createServerUserData(dataToSendServer);
    }


    const getUserAvailableTime = React.useCallback(async() => {
        const availableTime = await fetchServerAvailableTime();

        // should probably make a time atom and use that to not cause lots of refreshes?
        setUserData((existingUserData) => {
            if (!existingUserData) return;
            return {
                ...existingUserData,
                availableTime: availableTime
            }
        })
        
    }, [setUserData])

    const MINUTE_MS = 60000;

    React.useEffect(() => {
        const interval = window.setInterval(() => {
            //console.log('Logs every 1m ')
            console.log(`Trying to get the user availableTime~~~`)
            getUserAvailableTime();
        }, MINUTE_MS)

        return () => window.clearInterval(interval);
    }, [getUserAvailableTime, MINUTE_MS])



    const timeAvailable = React.useMemo(() => {
        if (!userData) return;
        let hours = Math.floor(userData.availableTime / 60);
        let minutes = userData.availableTime % 60;
        return <Box>
            <Typography>{`${hours}h ${minutes.toString().padStart(2, '0')}m`}</Typography>
            <TimeBar />
        </Box>
    }, [userData])

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position='fixed'>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <IconButton
                        size='large'
                        edge='start'
                        color='inherit'
                        aria-label='menu'
                        sx={{ mr: 2 }}
                    >
                        <IoIosMenu />
                    </IconButton>
                    <Button color="inherit" onClick={whatevaTestButton}><IoIosTime />{timeAvailable ? timeAvailable : 'TimeBar'}</Button>
                </Toolbar>
            </AppBar>
        </Box>
    )
}