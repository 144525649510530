import { userTestData } from "../data/test";
import { HabitData, HabitDataDictionary } from "../data/types";
import { serverUrl } from "../data/urlConfigs";

const url = serverUrl.baseUrl;

const baseHeader = {
    accept: 'application/json', 
    'Content-Type': 'application/json'
}

const authenticatedHeader = () => {
    return {
        ...baseHeader, 
        'authorization': document.cookie // What goes here???
    }
}

export const serverLogin = async(email: string, password: string) => {
    let response = await fetch(`${url}/login`, {
        method: 'POST', 
        headers: baseHeader, 
        body: JSON.stringify({email: email, password: password})
    })

    //The data returned will be the user authentication token
    const { token, data } = await response.json();

    if (!data || !token) {
        console.log(`The data from server login was undefined.`);
        return;
    } else {
        document.cookie = `growth_token=${await token}`
        return data;
    }
}

export const fetchServerUserData = async() => {
    let response = await fetch(`${url}/users/`, {
        method: 'GET', 
        headers: authenticatedHeader()
    })

    const data = await serverFeedback(response, `Successfully got user.`);

    if (!data) {
        console.log(`The received data is undefined`);
        return; 
    } else {
        console.log(`The received userdata is: `, data)
        return data;
    }
}

export const fetchServerAvailableTime = async() => {
    let response = await fetch(`${url}/times/available/`, {
        method: 'GET',
        headers: authenticatedHeader()
    })

    const data = await serverFeedback(response, 'Successfully got user available time.');

    if (!data) {
        console.log(`The received available time is undefined.`)
    } else {
        return data;
    }
}

// This will NOT return the depth
export const fetchServerUserHabits = async() => {
    let response = await fetch(`${url}/habits`, {
        method: 'GET',
        headers: authenticatedHeader()
    })

    const data = await serverFeedback(response, `Successfully found user habits.`);
    if (!data) {
        return console.log(`The received data was undefined`)
    } else {
        console.log(`The user's habit data from server is: `, data)
        return data;
    }
}

export const registerAndCreateServerUser = async(displayName: string, email: string, password: string) => {
    let response = await fetch(`${url}/register`, {
        method: 'POST', 
        headers: baseHeader, 
        body: JSON.stringify({displayName: displayName, email: email, password: password})
    })

    return (serverFeedback(response, `Tried to add user ${displayName} to server.`))
}

// Might not use this later on??
export const createServerUserData = async(userData: any) => {

    let response = await fetch(`${url}/users/`, {
        method: 'POST', 
        headers: authenticatedHeader(),
        body: JSON.stringify(userData)
    })

    return serverFeedback(response, `User ${userData.displayName} was added successfully to server.`)
}

//The below should not be used anymore??
export const createServerHabitsData = async(habitsData: HabitDataDictionary) => {
    let response = await fetch(`${url}/habits/${userTestData.email}`, {
        method: 'POST', 
        headers: {
            accept: 'application/json',
            'Content-Type': 'application/json'
        }, 
        body: JSON.stringify(habitsData)
    })

    return serverFeedback(response, `User's habitData was added successfully to server.`)
}

export const createServerHabit = async(habit: HabitData) => {

    let response = await fetch(`${url}/habits/add`, {
        method: 'POST',
        headers: authenticatedHeader(),
        body: JSON.stringify(habit)
    })

    return serverFeedback(response, `Habit ${habit.label} was created on server`);
}

export const updateServerHabitDuration = async(habitId:string, value: number) => {

    let response = await fetch(`${url}/habits/duration/${habitId}`, {
        method: 'PATCH', 
        headers: authenticatedHeader(),
        body: JSON.stringify({id: habitId, value: value})
    })

    return serverFeedback(response, `User's habitData was successfully updated on server.`);
}


export const deleteServerHabit = async(habit: HabitData) => {
    let response = await fetch(`${url}/habits/${habit.id}`, {
        method: 'DELETE',
        headers: authenticatedHeader(),
        body: JSON.stringify(habit)
    })

    return serverFeedback(response, `User's habit was deleted.`)
}

const serverFeedback = async(response: Response, isSuccessText: string) => {
    if (response.status === 200) {
        let { isSuccess, data, error } = await response.json();
        if (isSuccess) {
            if (isSuccessText) {
                console.log(isSuccessText)
            }
            return data;
        }
        if (error) {
            console.error(error);
            return undefined
        }
    } else {
        console.log(`Something went wrong with trying to contact the server.`, response.status)
    }
}

// const convertServerUserData = (serverUserData: any) => {
//     return { 
//         id: serverUserData.id,
//         displayName: serverUserData.displayName,
//         email: serverUserData.email,
//     }
// }

// const convertToServerHabit = (habit: HabitData) => {

//     const serverHabitObj = {
//         habit: habit.label, 
//         value: habit.value,
//         depth: habit.depth, 
//         parent: habit.parent, 
//     }

//     return serverHabitObj;
// }