import { HabitData, HabitDataDictionary } from "../data/types";



export const convertToDictionaryWithDepth = (habitsData: HabitData[]): HabitDataDictionary => {
    const dictionary: HabitDataDictionary = {}
    
    for (let habit of habitsData) {
        dictionary[habit.id] = habit;
    }

    for (let id in dictionary) {
        let depth = 0;
        let currentHabit = dictionary[id];

        // Will NOT need this section if you change the habitData to use an/the id for parents instead
        // const labelToIdMap: { [key: string]: string } = {};
        // for (let habit of habitsData) {
        //     labelToIdMap[habit.label] = habit.id;
        // }

        // while (currentHabit.parent && labelToIdMap[currentHabit.parent]) {
        //     depth++;
        //     currentHabit = dictionary[labelToIdMap[currentHabit.parent]];
        // }

        // IF you change the habitData to use an/the id for parent properties, use this instead of the
        // above while look, also can get rid of the labelToIdMap section
        while (currentHabit.parent && dictionary[currentHabit.parent]) {
            console.log(`The habit depth is going up~`)
            depth++;
            currentHabit = dictionary[currentHabit.parent];
        }

        //console.log(`The depth for ${dictionary[id].label} is`, depth)

        dictionary[id].depth = depth;
    }

    return dictionary;
}