import React from 'react';
import { useRecoilValue } from 'recoil';
import { habitDataAtom } from '../recoil/recoilStates';
import { HabitCard } from '../components/HabitCard';
import { Header } from '../components/Header';
import { Box } from '@mui/material';
import { HabitAddButton } from '../components/HabitAddButton';
import { appContentStyle } from '../App';



export const Home = () => {
    const habitData = useRecoilValue(habitDataAtom);

    const habitCardJsx = React.useMemo(() => {
        return Object.keys(habitData).map((habitName) => {

            return (
                <HabitCard key={habitName} habit={habitData[habitName]}
                //marginLeft={`${habitData[habitName].depth * 8}`}
                />)
        })
    }, [habitData])

    return (
        <>
            <Header />
            <Box sx={appContentStyle}>
                {habitCardJsx}

            </Box>
            {/* <HabitFloatingAddButton /> */}
            <HabitAddButton />
        </>
    )
}